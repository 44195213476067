import React from 'react';
import logo from './logo.svg';
import './App.css';
import Terminal from './components/terminal'
import * as firebase from "firebase/app"
import "firebase/auth"

firebase.initializeApp({
  apiKey: "AIzaSyC2cDXbuMUAwQskPEFeNB_mQ-nT1NPrJAw",
  authDomain: "mondi-gvhxck.firebaseapp.com",
  databaseURL: "https://mondi-gvhxck.firebaseio.com",
  projectId: "mondi-gvhxck",
  storageBucket: "mondi-gvhxck.appspot.com",
  messagingSenderId: "763841704392",
  appId: "1:763841704392:web:89738877b85e25bf19d0fd",
  measurementId: "G-ZDT5X8QW93"
});

class App extends React.Component {
  xterm
  componentDidMount() {
    runFakeTerminal(this.refs.xterm);
  }

  componentWillUnmount() {
    this.refs.mainDeviceComponent.componentWillUnmount();
  }

  render() {
    return (
      <div className="App">
        <Terminal ref='xterm'/>
      </div>
    );
  }
}

function runFakeTerminal(xterm) {
  const term = xterm.getTerminal();
  var shellprompt = '$ ';
  let command = '';
  let tempFlag = localStorage.getItem('loginSessionOngoing')
  console.log('obtianed tempFlag: ', tempFlag);
  let loginSessionOngoing = tempFlag ? JSON.parse(tempFlag) : false;
  console.log('obtianed loginSessionOngoing: ', loginSessionOngoing);
  let loginStage = 1;
  let startedFresh = true;
  let isPassword = false;
  let username = "";
  let password = "";
  let acceptInput = true;
  function prompt () {
    xterm.write('\r\n' + shellprompt);
    command = '';
  };
  console.log("startedFresh: ", startedFresh);
  console.log("!loginSessionOngoing && startedFresh: ", !loginSessionOngoing && startedFresh)
  if(!loginSessionOngoing && startedFresh){
    xterm.writeln('Hi, I am Mondai');
    xterm.writeln('I was created to be your work assistant.');
    xterm.writeln('To know more about what I can do you can type help.');
    xterm.writeln('');
    prompt();
  }else if(loginStage===1 && startedFresh){
    xterm.writeln("Logging you in...");
    setTimeout(()=> {
      xterm.write("\r\n");
      xterm.writeln("Coulnd't Log you in, please try again");
      localStorage.setItem("loginSessionOngoing", false);
      prompt();
      loginSessionOngoing = false;
    }, 1000*60*1.5)
  }


  term.onKey(({key, domEvent}) => {
    if(acceptInput){
      var printable = (
        !domEvent.altKey && !domEvent.ctrlKey && !domEvent.metaKey && !loginSessionOngoing && loginStage !=1
      );
  
      if (domEvent.keyCode == 13) {
        let commandLocal = command;
        if(!loginSessionOngoing){
          performCommand(xterm, commandLocal);
        }else if(username.length && password.length){
          console.log("performing login with the username: ", username);
          console.log("performing login with the password: ", password);
          performLogin(username, password);
        }else if(username.length){
          console.log("setting everything up for password");
          isPassword = true;
          xterm.write('\r\n' + 'Password: ');
        }else{
          xterm.writeln('\r\n' + 'I have lost my mind!');
        }
        if(!loginSessionOngoing && acceptInput)
            prompt();
      } else if (domEvent.keyCode == 8) {
        // Do not delete the prompt
        if(command.length>0){
          xterm.write('\b \b');
        }
        command = command.substring(0, command.length-1);
      } else if (!loginSessionOngoing && printable) {
        startedFresh = false;
        command = `${command}${key}`;
        xterm.write(key);
      }else if(printable && loginSessionOngoing && !isPassword){
        username = `${username}${key}`;
        xterm.write(key);
      }else if(printable && loginSessionOngoing && isPassword){
        password = `${password}${key}`;
      }
    }
  });
  function resetState(){
    username = '';
    password = '';
    loginSessionOngoing = false;
    console.log("username after reset state: ", username);
    console.log("password after reset state: ", password);
    console.log("loginSessionOngoing after reset state: ", loginSessionOngoing);
  }

  const performCommand = (xterm, command) => {
    switch(command){
      case 'help': 
                window.open('https://mondi-gvhxck.web.app/', '_blank');
                break;
      case 'login': 
                command = '';
                initiateLogin(xterm);
                break;
      default: 
              serverHelpMe(xterm, command);
              break;
    }
  }
  
  const initiateLogin = (xterm) => {
    loginSessionOngoing = true;
    localStorage.csetItem('loginSessionOngoing', true);
    let provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithRedirect(provider);
  }
  
  const performLogin = (username, password) => {
    resetState();
    console.log("username: ", username);
    console.log("password: ", password);
    prompt();
  }
  
  const serverHelpMe = async (xterm, command) => {
    acceptInput = false;
    xterm.write('\r\n' + 'Loading...');
    const response = await fetch(`https://api.mondai.apps.fountane.io/`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify({
          type: 'mondaiterminal',
          message: command
        }) // body data type must match "Content-Type" header
      });
      let data =  await response.json(); //
      xterm.write('\x1b[2K\r');
      console.log("data obtained from the server: ", data);
      xterm.writeln(data.message? data.message :"No Response");
      prompt();
      acceptInput = true;
  }

  firebase.auth().getRedirectResult().then(function(result) {
    if (result.credential) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = result.credential.accessToken;
      // ...
    }
    // The signed-in user info.
    var user = result.user;
    loginStage = 2;
    if(user){
      xterm.write("\r\nContacting Server...")
      console.log("this is the obtained user: ", user);
      loginToServer(xterm, user);
    }
  }).catch(function(error) {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    // The email of the user's account used.
    var email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    var credential = error.credential;
    console.error("error from firebase auth: ", error);
    // ...
  });

  async function loginToServer(xterm, user){
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(async function(idToken) {
      console.log("user idToken obtained: ", idToken);
      const response = await fetch(`http://konem.xyz:5020/api/v1/user/login?token=${idToken}`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify({}) // body data type must match "Content-Type" header
      });
      let data =  await response.json(); //
      xterm.writeln("You are all set!!");
      resetState();
      prompt();
      console.log("data obtained from the server: ", data);
    }).catch(function(error) {
      // Handle error
    });
  }
}

export default App;
